import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import locale from "antd/locale/en_US"; //
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#015B60",
          borderRadius: 4,
          colorBorder: "#89a6af",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </BrowserRouter>
);
// serviceWorkerRegistration.register();
