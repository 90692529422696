import React, { useEffect, useState } from "react";
import { ConfigProvider, notification } from "antd";
import { useNavigate } from "react-router-dom";
import RouteSetup from "./router";
// import { fetchToken, onMessageListener } from "./firebase";
import { baseUrl } from "./utils";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const App = () => {
  const [deviceToken, setDeviceToken] = useState();
  const navigate = useNavigate();

  // fetchToken(setDeviceToken);

  // useEffect(() => {
  //   let authToken = localStorage.getItem("x-auth-token");
  //   if (authToken && deviceToken) {
  //     sendTokenToServer(deviceToken);
  //   }
  //   onMessageListener()
  //     .then((payload) => {
  //       notification.open({
  //         type: "info",
  //         duration: null,
  //         message: payload.notification.title,
  //         description: payload.notification.body,
  //         onClick: () => navigate("/notifications"),
  //       });
  //     })
  //     .catch((err) => console.log("failed: ", err));
  // }, [deviceToken, navigate]);

  // const sendTokenToServer = async (deviceToken?: any) => {
  //   let authToken = localStorage.getItem("x-auth-token");
  //   const requestData = {
  //     device_token: deviceToken,
  //   };

  //   try {
  //     const response = await fetch(`${baseUrl}/user/device-token`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // };

  return <RouteSetup />;
};

export default App;
