import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
const Loader = lazy(() => import("../common/Loader"));

const Dashboard = lazy(() => import("../pages/dashboard"));
const EmployeesReward = lazy(() =>
  import("../pages/dashboard/ProActiv/RewardRedemptionDetail")
);
const ActivityTracking = lazy(() =>
  import("../pages/dashboard/ProActiv/ActivityTrackingDetail")
);

const MentalHealthRisk = lazy(() =>
  import("../pages/dashboard/ProActiv/MentalHealthRiskDetail")
);

const PhysicalHealthRisk = lazy(() =>
  import("../pages/dashboard/ProActiv/PhysicalHealthRiskDetail")
);

const PolicyUtilizationDetail = lazy(() =>
  import("../pages/dashboard/Insurance/PolicyUtilizationTableDetail")
);

const SeeWhatsNew = lazy(() => import("../pages/SeeWhatsNew"));

const Employees = lazy(() => import("../pages/employees"));
const AddEmployee = lazy(() => import("../pages/AddEmployee"));
const EditEmployee = lazy(() => import("../pages/EditEmployee"));
const KycUpdate = lazy(() => import("../pages/KycUpdate"));
const AddDependent = lazy(() => import("../pages/AddDependent"));

const EmployeeDetail = lazy(() => import("../pages/EmployeeDetail"));

const BulkUploadList = lazy(() =>
  import("../pages/AddEmployee/BulkUploadPreview")
);
const AddSubscription = lazy(() =>
  import("../pages/EmployeeDetail/AddSubscription")
);

const Policy = lazy(() => import("../pages/Policy"));
const ProActiveBenefits = lazy(() =>
  import("../pages/Policy/ProActiveBenefits")
);
const InsuranceBenefits = lazy(() =>
  import("../pages/Policy/InsuranceBenefits")
);
const Support = lazy(() => import("../pages/support"));
const Settings = lazy(() => import("../pages/settings"));
const Notifications = lazy(() => import("../pages/notifications"));
const Login = lazy(() => import("../pages/login"));
const PageNotFound = lazy(() => import("../pages/404"));

const MostVisitedServiceProviderDetail = lazy(() =>
  import("../pages/dashboard/Insurance/MostVisitedServiceProviderDetail")
);

const RestPasswordReq = lazy(() => import("../pages/ResetPasswordReq"));
const RestPassword = lazy(() => import("../pages/ResetPassword"));

const RouteSetup = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/see-whats-new"
          element={
            <PrivateRoute>
              <SeeWhatsNew />
            </PrivateRoute>
          }
        />

        <Route
          path="/most-visited-service-providers"
          element={
            <PrivateRoute>
              <MostVisitedServiceProviderDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/insurance/insurance-policy-utilisation"
          element={
            <PrivateRoute>
              <PolicyUtilizationDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-reward"
          element={
            <PrivateRoute>
              <EmployeesReward />
            </PrivateRoute>
          }
        />
        <Route
          path="/activity-tracking"
          element={
            <PrivateRoute>
              <ActivityTracking />
            </PrivateRoute>
          }
        />
        <Route
          path="/mental-health-risk"
          element={
            <PrivateRoute>
              <MentalHealthRisk />
            </PrivateRoute>
          }
        />
        <Route
          path="/physical-health-risk"
          element={
            <PrivateRoute>
              <PhysicalHealthRisk />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees"
          element={
            <PrivateRoute>
              <Employees />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees/add"
          element={
            <PrivateRoute>
              <AddEmployee />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees/edit/:id"
          element={
            <PrivateRoute>
              <EditEmployee />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees/kyc-update/:id"
          element={
            <PrivateRoute>
              <KycUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees/add-dependent/:member_account_id"
          element={
            <PrivateRoute>
              <AddDependent />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees/:id"
          element={
            <PrivateRoute>
              <EmployeeDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/employee/add-subscription/:account_id"
          element={
            <PrivateRoute>
              <AddSubscription />
            </PrivateRoute>
          }
        />

        <Route
          path="/bulkuploaded-preview"
          element={
            <PrivateRoute>
              <BulkUploadList />
            </PrivateRoute>
          }
        />
        <Route
          path="/policy"
          element={
            <PrivateRoute>
              <Policy />
            </PrivateRoute>
          }
        />
        <Route
          path="/policy/pro-active-benefits"
          element={
            <PrivateRoute>
              <ProActiveBenefits />
            </PrivateRoute>
          }
        />
        <Route
          path="/policy/insurance-benefits"
          element={
            <PrivateRoute>
              <InsuranceBenefits />
            </PrivateRoute>
          }
        />

        <Route
          path="/support"
          element={
            <PrivateRoute>
              <Support />
            </PrivateRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/reset-password-request" element={<RestPasswordReq />} />
        <Route path="/reset-password/:token" element={<RestPassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RouteSetup;
